import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
    templateUrl: 'oddsEnd.component.html'
})

export class OddsEndComponent {
  
  constructor() {
   
  }
  ngOnInit() {
 //  $('.navBar').hide(); 
 
  }

}
