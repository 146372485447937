import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
    templateUrl: 'ironMan.component.html'
})

export class IronManComponent {
  
  constructor() {
   
  }
  ngOnInit() {
    $('.navBar').hide();
  }

}
