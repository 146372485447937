import { Component, OnInit, Input } from '@angular/core';
import { ICurrentWorkData } from '../data/ICurrentWorkData';
import {CurrentWorkData } from '../services/currentWorkData.service';
import { Router } from '@angular/router';
import { MessageService } from '../services/message.service';
import { Subscription } from 'rxjs/Subscription';
declare var $: any;

@Component({
    selector: 'carousel',
    templateUrl: 'carousel.component.html'
})

export class CarouselComponent implements OnInit {
    message: any = 'homeimage';
    subscription: Subscription;
    test: number;
    @Input() currentWork: ICurrentWorkData;
    displayWork: string;
    testPic = 'SAM_2000.JPG';
    picture = 'pic';
    router: Router;
    id: any;
    length: number;

    ngOnInit() {
        this.test = 0;
        this.length = this.currentWork.pictureUrls.length;
        this.displayWork = this.currentWork.pictureUrls[this.test];
        this.movePicture(this.test++);
   }
   rightClick() {
        if (this.test >= this.length) {
            this.test = 0;
        }
        if (this.test < this.length) {
            this.displayWork = this.currentWork.pictureUrls[this.test];
            this.movePicture(this.test);
            ++this.test;
        }
        
    }

    leftClick() {
        if (this.test < 0) {
            this.test = this.length - 1;
        }
        if (this.test >= 0) {
            this.displayWork = this.currentWork.pictureUrls[this.test];
            this.movePicture(this.test);
            --this.test;
        }
    
    }
   linkClick () {
     $('#image').dialog('open');
   }
    movePicture(index: number) {
        $('#workPic').css('backgroundImage', 'url(' + this.displayWork + ')');
        $('#workPic').fadeTo('fast', 1);
    }
}


