import { Component,  OnInit } from '@angular/core';
import { IExhibits } from '../../data/IExhibitsData';
import { ExhibitsData } from '../../services/exhibitsData.service';
import { MessageService } from '../../services/message.service';
import { BackgroundImage } from '../../common/backgroundImage.component';
declare var $: any;

@Component({
    selector: 'app-progress-page',
    // templateUrl: 'exhibits.component.html'
    templateUrl: 'exhibition.component.html'
})

export class ExhibitsComponent implements OnInit{
  //  private exhibits: IExhibits[];
    private surprise2 = '/assets/workProgress/surprise4.jpg';
    private surprise1 = '/assets/workProgress/surprise1.jpg';
    private background = '/assets/backgroundColor.jpg';
    private exhidePopover = true;
    private linkDisplay: boolean;
    private detailsDisplay: boolean;
    private workPicture: string;
    private workButton: string;
    private color;
  //  private exhibits: ExhibitsData;
    private exhibits: IExhibits[];
    private number: number;
    private description: string;
    private picture: string; 
   
        
    constructor(private messageService: MessageService, private exhibitsData: ExhibitsData, 
        private backgroundImage: BackgroundImage ) {
        this.messageService.sendMessage('base');
       
    }

    ngOnInit() {
        this.exhibits = this.exhibitsData.getExhibitsData_();
        this.backgroundImage.setImage(this.background);
        this.workPicture = 'workPicture';
        this.workButton = 'genePicSize'; 
      
       // this.description = this.exhibits[0].description;
    }

 //   getThumbnailData(index: number): IThumbnail[] {
 //       return this.thumbnails.getThumbnails(index);
  //  }

    displayPic(index: number) {
        this.exhidePopover = false;
    }

    showPopover0(number) {
        this.number = this.exhibits[number].postNumber;
        this.exhidePopover = false;
        this.description = this.exhibits[number].description;
        this.picture = this.exhibits[number].pictureUrl;
        $("#exButton").addClass(number);
    }

    mouseOver(index) {
        this.color = $('div#show:eq(' + (index) + ')').css('background-color');
        $('div#show:eq( '+ (index) + ')').css('background-color', 'rgb(226, 250, 219)');   

    }

    mouseOut(index) {
        $("#exButton").css('background-color' , 'blanchedalmond');
        $('div#show:eq( '+ (index) + ')').css('background-color', this.color);
     //   $('div#show:eq( '+ (this.number) + ')').css('width', this.hSize);     

    }

    showDetails() {
        this.detailsDisplay = false;
        this.linkDisplay = true;
        let postion = $("#link").position()
        var top = postion.top;
        $("#details").position(top, top); 
    }
    hideDetails() {
        this.detailsDisplay = true;
        this.linkDisplay = false;
    }
    poClose() {
        this.exhidePopover = true;
    }

    linkOver(){
        $('#link').removeClass('linkOut');
        $('#link').addClass('linkOver');
        $('#link').css('font-weight', 'bolder' );
    }
    
    linkOut() {
        $('#link').removeClass('linkOver');
        $('#link').addClass('linkOut');
        $('#link').css('font-weight', 'normal' );
    }
    
}