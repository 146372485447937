import { Component, OnInit } from '@angular/core';
import { BackgroundImage } from '../../common/backgroundImage.component';
declare var $: any;

@Component({
    templateUrl: 'fun.component.html'
})

export class FunComponent {
  
    private background = '/assets/backgroundColor.jpg';
    private image =  '/assets/atWork1.jpg';

  constructor( private backgroundImage: BackgroundImage) {
 //   messageService.sendMessage('base');
  }

  ngOnInit() {
    this.backgroundImage.setImage(this.background);
  } 
}