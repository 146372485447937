import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
    templateUrl: 'resources.component.html'
})

export class ResourcesComponent {
  
  constructor() {
   
  }

  ngOnInit() {
    $('.navBar').hide();
  }

}
