import { Routes, RouterModule } from '@angular/router';
import { CurrentWorkComponent } from './pages/currentWork/currentWork.component';

import { ExhibitsComponent } from './pages/exhibits/exhibition.component';
import { HomePageComponent } from './pages/home/HomePage.component';
import { AboutMeComponent } from './pages/About/aboutMe.component';//
import { ContactMeComponent } from './pages/contactPage/contactMe.component';
import { FunPlacesComponent} from './pages/funThings/funPlaces.component';
import { FunComponent} from './pages/fun/fun.component';
// import { Error404Component } from './errors/404.component';
import { WorkOneComponent } from './pages/currentWork/works/workOne.component';
//import { WorkOneComponent } from './pages/works/workOne.component';

import { ResourcesComponent} from './pages/funThings/resources.component';
import { OddsEndComponent} from './pages/funThings/oddsEnd.component';
import { IronManComponent} from './pages/funThings/ironMan.component';

import { Test } from './pages/testFoundation/ftest';

export const appRoutes: Routes = [

   { path: '', redirectTo: '/home', pathMatch: 'full' }, 
    //{ path: 'currentWork/workOne/3', redirectTo: '/currentWork/workOne/3' }, 
   // { path: 'currentWork/workOneOne', component: WorkOneComponentOne},
   { path: 'home', redirectTo: '/home' , pathMatch: 'full'}, 
    { path: 'home', component: HomePageComponent}, 
    { path: 'currentWork', component: CurrentWorkComponent}, 
    { path: 'exhibits', component: ExhibitsComponent},
    { path: 'currentWork/workOne/:id', component: WorkOneComponent},
    { path: 'currentWork/workOne', component: WorkOneComponent},
   // { path: 'workOne/id', component: WorkOneComponent},
    { path: 'workOne', component: WorkOneComponent},
    { path: 'aboutMe', component: AboutMeComponent},
    { path: 'contactMe', component: ContactMeComponent},
    { path: 'funPlaces', component: FunPlacesComponent},
    { path: 'ironMan', component: CurrentWorkComponent},
   // { path: 'ironMan', component: IronManComponent},
    { path: 'resources', component: ResourcesComponent},
    { path: 'oddsEnd', component: OddsEndComponent},
    { path: 'fun', component: FunComponent},
    { path: 'test', component: Test}
  // { path: '', component: HomePageComponent}
    
];
