import { Component, Inject, Input, OnInit } from '@angular/core';
import { JQ_TOKEN } from '../services/jQuery.service';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './navbar.component.1.html',
    styles: [`
         li > a.active {color: rgb(255, 255, 255, 90%)}
        .tablink {color: rgb(255, 255, 255, 65%); }
    `]
})
export class NavBarComponent {
    backgound = 'assets/background2.jpg';
    color: any;

    constructor(@Inject(JQ_TOKEN) private $: any) { }

    ngOnInit () {
    
    }

    onClick() {
        
    }

    onMouseOver(tab: string) {
        this.color =  this.$('#' + tab).css('background-color');
        this.$('#' + tab).css('background-color', 'rgb(97, 255, 202, 30%');
    }
    onMouseOut(tab: string) {
        this.$('#' + tab).css('background-color', this.color);
    }

}
