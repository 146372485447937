import { Component, OnInit } from '@angular/core';
//import { $ } from 'protractor';
declare var $: any;

@Component({
    templateUrl: 'funPlaces.component.html'
})

export class FunPlacesComponent {

    
  constructor() {
   
  }

  ngOnInit() {
    const newLocal = '.navBar';
    $(newLocal).hide();
  }

}
