import { Component, OnInit, Input } from '@angular/core';
import { IThumbnail } from '../data/IThumbnailData';
declare var $: any;

@Component({
    selector: 'popoverContent',
    templateUrl: 'popoverContent.component.html'
})

export class PopoverContent implements OnInit {
    @Input() thumbNail: IThumbnail;
    @Input() poButton: string;
    @Input() poPicture: string;
    @Input() poIndex: number;
  //  @Input() pohidePopover: boolean;
    hidePopover = true;
    thumbnails: string;
    description: string;
    picture: string;
    borderColor: string;
    buttonClass: string;
    color: string;
    hSize: number;
    vSize: number;
   
    ngOnInit() {
        $('img#thumbnail:eq(' + (this.poIndex) + ')').addClass('genePic genePicSize');
     //   this.hidePopover = true;
     //   this.thumbnails ='./assets/workProgress/SC3/SC_3_2.jpg';
     //   this.description = 'this.thumbNail.description';
     //   this.picture = './assets/workProgress/SC3/SC_3_2.jpg';
     //   this.buttonClass = this.poButton;
        $('img#thumbnail:eq(' + (this.poIndex) + ')').addClass('mouseOut');
        this.hidePopover = true;
        this.thumbnails = this.thumbNail.thumbnail;
        this.description = this.thumbNail.description;
        this.picture = this.thumbNail.picture;
        this.buttonClass = this.poButton;
        $('img#thumbnail:eq( '+ (this.poIndex) + ')').addClass('outSize');
   }
   
    showPopover1(state: boolean) {
        this.hidePopover = state;
   }
   
   mouseOver(){
    // this.hidePopover = false;
    //   $('img#thumbnail:eq(' + (this.poIndex) + ')').removeClass('mouseOut');
    //    $('img#thumbnail:eq( '+ (this.poIndex) + ')').addClass('mouseOver');
        this.color = $('img#thumbnail:eq(' + (this.poIndex) + ')').css('border-color');
        $('img#thumbnail:eq( '+ (this.poIndex) + ')').css('border-color', 'lightblue');
      //  this.hSize = $('img#thumbnail:eq(' + (this.poIndex) + ')').css('width');
      //  $('img#thumbnail:eq( '+ (this.poIndex) + ')').removeClass('outSize');
      //  this.hSize = $('img#thumbnail:eq(' + (this.poIndex) + ')').addClass('overSize');
    }

   mouseOut() {
    //$('img#thumbnail:eq(' + (this.poIndex) + ')').removeClass('mouseOver');
   // $('img#thumbnail:eq(' + (this.poIndex) + ')').addClass('mouseOut');
      $('img#thumbnail:eq( '+ (this.poIndex) + ')').css('border-color', this.color);
      $('img#thumbnail:eq( '+ (this.poIndex) + ')').css('width', this.hSize);
    }

    linkOver(){
        $('.link').removeClass('linkOut');
        $('.link').addClass('linkOver');
    }
    
    linkOut() {
        $('.link').removeClass('linkOver');
        $('.link').addClass('linkOut');
    }
}
