import { Component } from '@angular/core';
import { IAboutMeData } from '../../data/IAboutMeData';
import { AboutMeData } from '../../services/aboutMeData.service';
import { MessageService } from '../../services/message.service';
import { BackgroundImage } from '../../common/backgroundImage.component';

@Component({
    templateUrl: 'contactMe.component.html'
})

export class ContactMeComponent {
    private aboutMe: IAboutMeData[];
    private emailAddress = 'email address';
    private messegeText = 'message';
    private textValue = 'initial value';
    private log = '';
    private background = '/assets/backgroundColor.jpg';
    private image =  '/assets/atWork1.jpg';

  constructor(private messageService: MessageService, private backgroundImage: BackgroundImage) {
    messageService.sendMessage('base');
  }

  ngOnInit() {
    this.backgroundImage.setImage(this.background);
  } 

  logText(contactMeForm): void {
    console.log(contactMeForm );
  }
  cancel(contactMeForm): void {
   // contactMeForm = '';
    console.log('cancel');
    console.log(contactMeForm.messege );
    console.log(contactMeForm.messege = '');
    console.log(contactMeForm.messege );

  }
}
