import { Injectable, EventEmitter } from '@angular/core';
import { INHERITED_CLASS } from '@angular/core/src/reflection/reflection_capabilities';
import { NG_PROJECT_AS_ATTR_NAME } from '@angular/core/src/render3/interfaces/projection';
import { workers } from 'cluster';
import { networkInterfaces } from 'os';
import { IThumbnail } from '../data/IThumbnailData';

@Injectable()
export class ThumbnailData {
  /*  getThumbnails(): IThumbnail[] {
        return THUMBNAILS;
    } */

    getThumbnails(index: number): IThumbnail[] {
        return THUMBNAILS[index];
    }

    getThumbnailClass(): string {
        return 'aboutButton';
    }

    getPictureClass(): string {
        return 'aboutPicture';
    }
}

const THUMBNAILS: IThumbnail[][] =[ 
   

    [
        {
            thumbnail: './assets/workProgress/SC3/SC_3_1.jpg',
            picture: './assets/workProgress/SC3/SC_3_1.jpg',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC3/SC_3_2.jpg',
            picture: './assets/workProgress/SC3/SC_3_2.jpg',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC3/SC_3_3.jpg',
            picture: './assets/workProgress/SC3/SC_3_3.jpg',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC3/SC_3_4.jpg',
            picture: './assets/workProgress/SC3/SC_3_4.jpg',
            description: ''
        },
    ],
    [
        {
            thumbnail: './assets/workProgress/SC6/SC1.JPG',
            picture: './assets/workProgress/SC6/SC1.JPG',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC6/SC2.JPG',
            picture: './assets/workProgress/SC6/SC2.JPG',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC6/SC3.JPG',
            picture: './assets/workProgress/SC6/SC3.JPG',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC6/SC4.jpg',
            picture: './assets/workProgress/SC6/SC4.jpg',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC6/SC5.jpg',
            picture: './assets/workProgress/SC6/SC5.jpg',
            description: ''
        },
    ],
    [
       
        {
            thumbnail: './assets/workProgress/SC4/SC_4_1.jpg',
            picture: './assets/workProgress/SC4/SC_4_1.jpg',
            description: 'ArtIntersection'
        },
        {
            thumbnail: './assets/workProgress/SC4/SC_4_6.jpg',
            picture: './assets/workProgress/SC4/SC_4_6.jpg',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC4/SC_4_4.jpg',
            picture: './assets/workProgress/SC4/SC_4_4.jpg',
            description: ''
        },
    ],
    [
        
        {
            thumbnail: './assets/workProgress/SC2/SC_2_3.jpg',
            picture: './assets/workProgress/SC2/SC_2_3.jpg',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC2/SC_2_5.jpg',
            picture: './assets/workProgress/SC2/SC_2_5.jpg',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC2/SC_2_6.jpg',
            picture: './assets/workProgress/SC2/SC_2_6.jpg',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC2/SC_2_2.jpg',
            picture: './assets/workProgress/SC2/SC_2_2.jpg',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC2/SC_2_1.jpg',
            picture: './assets/workProgress/SC2/SC_2_1.jpg',
            description: ''
        }
    ],
    [
        {
            thumbnail: './assets/workProgress/SC5/Strickland_RodeoDreams_1.jpg',
            picture: './assets/workProgress/SC5/Strickland_RodeoDreams_1.jpg',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC5/Strickland_RodeoDreams_2.JPG',
            picture: './assets/workProgress/SC5/Strickland_RodeoDreams_2.JPG',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC5/Strickland_RodeoDreams_3.jpg',
            picture: './assets/workProgress/SC5/Strickland_RodeoDreams_3.jpg',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC5/Strickland_RodeoDreams_5.jpg',
            picture: './assets/workProgress/SC5/Strickland_RodeoDreams_5.jpg',
            description: ''
        },
        {
            thumbnail: './assets/workProgress/SC5/Strickland_RodeoDreams_6.jpg',
            picture: './assets/workProgress/SC5/Strickland_RodeoDreams_6.jpg',
            description: ''
        }
    ]
];
