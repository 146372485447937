import { NgModule, Component, Output, Inject } from '@angular/core';
import { EventEmitter } from '@angular/core';
import {CurrentWorkData } from '../../services/currentWorkData.service';
import { ICurrentWorkData } from '../../data/ICurrentWorkData';
import { MessageService } from '../../services/message.service';
import { BackgroundImage } from '../../common/backgroundImage.component';

declare var $: any;

@Component({
   templateUrl: 'currentWork.component.html'
})

export class CurrentWorkComponent {
    private id: number;
    private background = '/assets/backgroundColor.jpg';
    private currentWorkList: ICurrentWorkData[];
    @Output() eventClick = new EventEmitter();

  constructor(private messageService: MessageService, private backgroundImage: BackgroundImage, 
    private currentWorkData: CurrentWorkData) {
      this.currentWorkList = currentWorkData.getCurrentWork();
      this.messageService.sendMessage('base');
  }

  ngOnInit() {
    this.backgroundImage.setImage(this.background);
  }  
  
  onSelect (currentWork) {
   // this.messageService.sendMessage(currentWork.backGroundUrl);
    this.messageService.sendMessage(currentWork);
   
    //this.messageService.sendMessage(this.background);
  }
}
