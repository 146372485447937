import { Component, OnDestroy, OnInit, Inject, OnChanges,  } from '@angular/core';
import { CurrentWorkData } from '../../services/currentWorkData.service';
//import { ICurrentWorkData } from '../../data/ICurrentWorkData';
import { BackgroundImage } from '../../common/backgroundImage.component';
import { element } from 'protractor';
//import { RouterModule, Routes } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
    selector: 'app-home-page',
    templateUrl: 'homePage.component.2.html'
})

export class HomePageComponent implements OnInit {
    private picture1 = '/assets/SAM_2000.jpg';
    private picture2 = '/assets/SAM_0763.JPG';
    private picture3 = '/assets/SAM_0763.JPG';
    private picture4 = '/assets/home/SC_2_0S.jpg';
    //private picture3 = '/assets/SAM_763_T.jpg';
    private geneImage = '/assets/home/Gene_2.jpg';
    private background = '/assets/backgroundColor.jpg';
    private index = 0;
    private timerToken: any;

    private homePage1 = '/assets/home/SC_2_0S.jpg';
    private homePage2 = '/assets/home/SC_2_0_2S.jpg';
    private homePage3 = 'assets/home/SAM_770_Trans1.jpg';
    private homePage4 = 'assets/home/SAM_763_T.jpg';
    private homePage5 = 'assets/home/S2_Adjusted_6.jpg';
    //private homePage6 = '/assets/home/SC_2_0_2.jpg';
    private homePage7 = '/assets/home/SC_4_3.jpg';
    //private currentWork: any;
    
   
    constructor(private currentWorkData: CurrentWorkData, private backgroundImage: BackgroundImage) {
      //  private route: ActivatedRoute) {
         
        }
    

    ngOnInit() {
     //   this.currentWork = this.currentWorkData.getCurrentSingleWork(this.route.snapshot.params['id']);
      //  this.currentWork = this.currentWorkData.getCurrentSingleWork(0);
     // this.redirect = this.route.snapshot.params['id'];
     this.firstImage();
     $('#footer').css('background-image', 'url(' + this.background + ')');
      this.backgroundImage.setImage(this.background);
      //this.firstImage();
      this.slideShow();
      $('.fadein img').hide();
    }

    firstImage() {
        $('.firstfade img').fadeIn(100);
       // $('.fadein  img').fadeIn(1000).fadeOut(1750);
    }

    slideShow() {
     //  $('.fadein img').show();
     //   $('.fadein  img').fadeIn(3000).fadeOut(1750);
    // $('.fadein :first-child').hide()
    // .next('img').fadeIn(50).fadeOut(1750)
    // .end().appendTo('.fadein');
      this.timerToken = setInterval(function () {
            $('.fadein :first-child').hide()
                .next('img').fadeIn(1750).fadeOut(1750)
                .end().appendTo('.fadein');
        }, 4500); 
    } 

    mouseOver(){
        
        $('.funLink')[0].setAttribute('color', 'green');
    //        $('img#thumbnail:eq( '+ (this.poIndex) + ')').addClass('mouseOver');
        }
    
     /*  mouseOut() {
        $('img#thumbnail:eq(' + (this.poIndex) + ')').removeClass('mouseOver');
        $('img#thumbnail:eq(' + (this.poIndex) + ')').addClass('mouseOut');
        }
        */

    ngOnDestroy() {
        if (this.timerToken) {
            clearInterval(this.timerToken);
        }
    }

    onClick( ) {
       
    }
}
