import { Injectable, EventEmitter } from '@angular/core';
import { IThumbnail } from '../data/IThumbnailData';

@Injectable()
export class AboutMePopoverData {
    getThumbnails(): IThumbnail[] {
        return THUMBNAILS;
    }

    getThumbnail(id: number): IThumbnail {
        return THUMBNAILS[id];
    }

    getThumbnailClass(): string {
        return 'aboutButton';
    }

    getThumbnailAboutClass(): string {
        return 'aboutButton';
    }

    getPictureClass(): string {
        return 'aboutPicture';
    }
}
const THUMBNAILS: IThumbnail[] = [
    {
        thumbnail: '/assets/aboutMe/mom.JPG',
        picture: '/assets/aboutMe/mom_large.JPG',
        description: ''
    },
    {
        thumbnail: './assets/aboutMe/etching.JPG',
        picture: './assets/aboutMe/etching_large.JPG',
        description: ''
    },
    {
        thumbnail: './assets/aboutMe/window.JPG',
        picture: './assets/aboutMe/window_large.JPG',
        description: ''
    },
    {
        thumbnail: './assets/aboutMe/vickie.JPG',
        picture: './assets/aboutMe/vickie_large.JPG',
        description: ''
    },
    {
        thumbnail: './assets/aboutMe/SAM_1745.JPG',
        picture: './assets/aboutMe/SAM_1745.JPG',
        description: ''
    },
    {
        thumbnail: './assets/aboutMe/SAM_2135_.JPG',
        picture: './assets/aboutMe/SAM_2135_.JPG',
        description: ''
    },
    {
        thumbnail: './assets/aboutMe/SAM_0290_.JPG',
        picture: './assets/aboutMe/SAM_0290_.JPG',
        description: ''
    }
];