import { Component, OnInit, Inject } from '@angular/core';
import { AboutMePopoverData } from '../../services/aboutMePopoverData.service';
import { MessageService } from '../../services/message.service';
import { JQ_TOKEN } from '../../services/jQuery.service';
import { BackgroundImage } from '../../common/backgroundImage.component';
declare var $: any;

@Component({
    templateUrl: 'aboutMe.component.2.html'
})

export class AboutMeComponent implements OnInit {
    backgound = 'assets/background2.jpg';
    private popover0: any;
    private popover1: any;
    private popover2: any;
    private popover3: any;
    private popover4: any;
    private popover5: any;
    private popover6: any;
    private geneImage2 = '/assets/aboutMe/SAM_Work.jpg';
    private geneImage1 = '/assets/aboutMe/SAM_Work_Hammer.jpg';
    private aboutPicture: any;
    private aboutButton: any;
    private background = '/assets/backgroundColor.jpg';
      
    constructor(@Inject(JQ_TOKEN) private $: any, private aboutMeData: AboutMePopoverData, 
        private messageService: MessageService, private backgroundImage: BackgroundImage, ) {
        this.popover0 = this.aboutMeData.getThumbnail(0);
        this.popover1 = this.aboutMeData.getThumbnail(1);
        this.popover2 = this.aboutMeData.getThumbnail(2);
        this.popover3 = this.aboutMeData.getThumbnail(3);
        this.popover4 = this.aboutMeData.getThumbnail(4);
        this.popover5 = this.aboutMeData.getThumbnail(5);
        this.popover6 = this.aboutMeData.getThumbnail(6);
        this.aboutPicture = this.aboutMeData.getPictureClass();
        this.aboutButton = this.aboutMeData.getThumbnailClass();
        this.messageService.sendMessage('base');
   }

   ngOnInit () {
        this.$('#background').css('background-image', 'url(' + this.backgound + ')');
        this.$('#tab1').removeClass('active');
        this.backgroundImage.setImage(this.background);
   }
}


