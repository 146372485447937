import { Injectable, KeyValueDiffers, OnInit } from '@angular/core';
import { ICurrentWorkData } from '../data/ICurrentWorkData';
import { ThumbnailData } from './thumbnailData.service';
import { IThumbnail } from '../data/IThumbnailData';
import { getCiphers } from 'tls';
import { SELECT_PANEL_INDENT_PADDING_X, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { baseDirectiveCreate } from '@angular/core/src/render3/instructions';
import { networkInterfaces } from 'os';
import { withLatestFrom } from 'rxjs-compat/operator/withLatestFrom';

@Injectable()
export class CurrentWorkData implements OnInit{
   /* getThumbnailData(): Observable<IThumbnail[]> { 
        const subject = new Subject<IThumbnail[]>();
        setTimeout (() => {subject.next(CURRENTWORKDATA); subject.complete(); }, 100);
        return subject;
    }  */
    private tn: ThumbnailData;
    private str: string;
    
    constructor (private thumbnails: ThumbnailData) {
        this.str = 'new';
        this.tn = thumbnails;
        this.str = 'old';
    }

    ngOnInit() {
    }

    getCurrentWorkData(index: number): IThumbnail[] {
        return this.thumbnails.getThumbnails(index);
    }

    getCurrentWork(): ICurrentWorkData[] {
        return CURRENTWORKDATA;
    }

    getCurrentSingleWork(id: number): ICurrentWorkData {
        return CURRENTWORKDATA[id];
    }

    getLength(): number {
        return CURRENTWORKDATA.length;
    }
}

const CURRENTWORKDATA: ICurrentWorkData[] = [
    
   {
        projectNumber: 0,
        heading: '',
        backGroundUrl: '/assets/workProgress/SC3/SC_3_0_1.jpg',
        pictureUrls : [ '/assets/workProgress/SC3/SC_3_0_1.jpg', '/assets/workProgress/SC3/SC_3_2.jpg', '/assets/workProgress/SC3/SC_3_4.jpg', '/assets/workProgress/SC3/SC_3_1.jpg', '/assets/workProgress/SC3/SC_3_3.jpg'],
       
        title: 'Grace',
        description: 'The arrangement is both quiet and intimate. It relies on the the close interaction of the figures to describe their story. \
        The language of their bodies suggest an experience that is both personal and intense. ',

        text: 'I wanted to try an arrangement that was both intimate and quiet, that might invite contemplation. \
        I also was looking for an emotional statement - perhaps about grace and acceptance. \
        <br/><br/> \
        As I worked on the relationships, the details in the body language seemed to drive the story – a raised shoulder, the movement of the arms, \
        the placement of the hands, and direction of the finger tips. Each detail seemed to be a part of the conversation between the figures. \
        <br/><br/> \
        The interactions are subtle, but intense.  Theirs is a story is that is revealed gently over time. \
        <br/>',
        materials: 'brass, iron, solder',
        dimensions: 'h-82, w-37, d-37',
        weight: '87 lbs.',
        price: '$4,800'
    }, 
    {
        projectNumber: 1,
        heading: '',
        backGroundUrl: '/assets/workProgress/SC6/SC1.JPG',
        pictureUrls : ['/assets/workProgress/SC6/SC1.JPG', '/assets/workProgress/SC6/SC2.JPG', '/assets/workProgress/SC6/SC3.JPG', 
        '/assets/workProgress/SC6/SC4.jpg', '/assets/workProgress/SC6/SC5.jpg'], 
       
        title: 'Ascension',
        description: 'It has been said that in our lives, change is the only constant. This work is about the challenges we face, \
        and that with perseverance, we might be a partner in our destinies.',         
        
        text: '“Ascending Man” was started about a year ago when I was facing some major challenges in my life. It was \
        intended to be a personal statement about being bold and courageous in the face of adversity. The figure is posed \
        driving forward with hope, into the unknown. Most of all, it was created to affirm optimism in the face of an \
        uncertain future.  \
        <br/><br/> \
        Since that time, we have all experienced social and personal upheavals.  Now, in these very difficult times, it \
        seems the spirit of this work is even more appropriate. “Ascending Man” carries the conviction that, with courage \
        and resolve, we will overcome, and our spirit will know renewal. \
        <br/>',
        materials: 'brass, iron, solder',
        dimensions: 'h-68, w-37, d-42',
        weight: '64 lbs.',
        price: '$4,800'
    },
    {
        projectNumber: 2,
        heading: '',
        backGroundUrl: '/assets/workProgress/SC4/SC_4_1.jpg',
        pictureUrls : ['/assets/workProgress/SC4/SC_4_1.jpg', '/assets/workProgress/SC4/SC_4_4.jpg', '/assets/workProgress/SC4/SC_4_6.jpg'], 
               
        title: 'A Delicate Balance',
        description: 'This is an attempt to explore the drama from closely intertwined forms. There is action and energy. \
        Each is separate, yet the actions of either one affects the fates of both. ',         
        
        text: 'Watching figure skating, I was taken by the stories created as the dancers skated. Intense emotions were evoked \
        by the many variations of their movements – passion, fear, and love. I was intrigued by the danger, and the faith each had in the other. \
        <br/><br/> \
        About halfway through this project, I became disapointed with the arrangement - the compostion seemed static. \
        I spent some time rearranging the positions, trying new relationships. \
        At some point figures began to lead the composition, I began to trust in what the figures were revealing to me. \
        <br/><br/> \
        The resulting arrangement has the movement and drama that I had been seeking. There is both desire and reluctance, \
        and an attempt to find unity through the delicate balancing of two lives! \
        <br/>',
        materials: 'brass, iron, solder',
        dimensions: 'h-70, w-42, d-27',
        weight: '89 lbs.',
        price: '$5,900'
    },
    {
    projectNumber: 3,
        heading: '',
        backGroundUrl: '/assets/workProgress/SC2/SC_2_0_9.jpg',
        pictureUrls : ['/assets/workProgress/SC2/SC_2_0_9.jpg', 
        '/assets/workProgress/SC2/SC_2_0_4.JPG', '/assets/workProgress/SC2/SC_2_0_5.JPG', '/assets/workProgress/SC2/SC_2_6.jpg'],
       
        title: 'The Joy of Desire',
        description: 'This work started with a lot of ideas - desire, affirmation, fulfilment. Elevated, the figures are trancending the physical. \
        This is a celebration of what it means to be alive. ',
        
        text: 'I wanted to say something that celebrates the human spirit – and  the moments in a story when desires become joy. \
        <br/><br/>\
        Everything about these figures moves upward, as the figures are being swept up in a celebration. There is a lightness to their being, suggesting \
        this is a transcendent moment. Minimal contact between the figures emphasizes a spiritual rather than a physical experience.\
        <br/><br/> \
        The result is a work that celebrates living. This work always makes me smile, and will always be one of my favorite creations.\
        <br/>',
        materials: 'brass, iron, solder',
        dimensions: 'h-82, w-39, d-34',
        weight: '102 lbs.',
        price: '$6,900'
    },
    {
        projectNumber: 4,
        heading: '',
        backGroundUrl: '/assets/workProgress/SC5/Strickland_RodeoDreams_1.jpg',
        pictureUrls : ['/assets/workProgress/SC5/Strickland_RodeoDreams_1.jpg','/assets/workProgress/SC5/Strickland_RodeoDreams_2.JPG', 
            '/assets/workProgress/SC5/Strickland_RodeoDreams_3.jpg','/assets/workProgress/SC5/Strickland_RodeoDreams_5.jpg',
            '/assets/workProgress/SC5/Strickland_RodeoDreams_6.jpg'],
       
        title: 'Rodeo Dreams',
        description: 'This is something a little different. It is a celebration of the fantasy, fun, and dreams of our childhoods. ',
        
        text: 'Give a boy a broom stick and a sock, and he will ride off on his horse. \
        Let him sit on the back of a turtle, and will be riding a bucking bronco.\
        <br/><br/> \
        How wonderful it is to see everything as a new adventure.\
        <br/>',
        materials:  'brass, iron, solder',
        dimensions: 'h-48, w-33, d-32',
        weight: '56 lbs.',
        price: '$3,800'
    }
];



