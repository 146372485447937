import {OnInit, Injectable } from '@angular/core';
declare var $: any;

@Injectable()
export class BackgroundImage implements OnInit {
    private startImage = 'assets/background2.jpg';
    private homeImage = 'assets/background2.jpg';
    
    ngOnInit() {
        $('#background_x').css('background-color: green');
    }

    setImage(image: string) {
        $('#background').css('background-image', 'url(' + image + ')');
    }

    setBaseImage() {
        $('#background').css('background-image', 'url(' + this.startImage + ')');
    }

    setHomeImage() {
        $('#background').css('background-image', 'url(' + this.homeImage + ')');
    }
}