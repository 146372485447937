import { Component, OnInit, Inject} from '@angular/core';
import {CurrentWorkData } from '../../../services/currentWorkData.service';
import {ICurrentWorkData } from '../../../data/ICurrentWorkData';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute } from '@angular/router';
import { BackgroundImage } from '../../../common/backgroundImage.component';
//import { BackgroundImage } from '/../common/backgroundImage.component';
import { IThumbnail } from 'src/app/data/IThumbnailData';
declare var $: any;

@Component({
   templateUrl: 'workOne.component.1.html',   
})

export class WorkOneComponent implements OnInit {
    private currentWork: any ;
  //  private videoImage = '../../assets/workProgress/VID_20181028_150336.mp4';
  //  private videoImage = 'https://www.youtube.com/watch?v=toKVC7cNqmQ';
    private hidePopover = true;
    private workData: IThumbnail[];
    private description: any;
    private router: Router;
    private title: string;
    private price: string;
    private materials: string;
    private dimensions: string;
    private weight: string;
    private index: number;
    private workPicture: string;
    private workButton: string;
    private background = '../../assets/backgroundColor.jpg';
    private image: string;
    private detailsDisplay: boolean;
    private linkDisplay: boolean;

    constructor( private currentWorkData: CurrentWorkData, private backgroundImage: BackgroundImage,
        private route: ActivatedRoute) {
    }

    ngOnInit() {
       
         this.currentWork = this.currentWorkData.getCurrentSingleWork(this.route.snapshot.params['id']);
         this.workData = this.currentWorkData.getCurrentWorkData(this.route.snapshot.params['id']);
     
         this.description = this.currentWork.text;
         this.title = this.currentWork.title;
         this.price = this.currentWork.price;
         this.materials = this.currentWork.materials;
         this.dimensions = this.currentWork.dimensions;
         this.weight = this.currentWork.weight;
        
         this.hidePopover = true;
         this.workPicture = 'workPicture';
         this.workButton = 'workButton mouseOut';
         this.backgroundImage.setImage(this.background);
         this.image = this.currentWork.backGroundUrl; 
         this.detailsDisplay = true;
         this.linkDisplay = false;
    }

    displayPic(index: number) {
        this.hidePopover = false;
    }

    showPopover0(index: number) {
        this.hidePopover = false;
    }

    showDetails() {
        this.detailsDisplay = false;
        this.linkDisplay = true;
        let postion = $("#link").position()
        var top = postion.top;
        $("#details").position(top, top); 
    }
    hideDetails() {
        this.detailsDisplay = true;
        this.linkDisplay = false;
    }
}




