import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs/RX';
import { IExhibits } from '../data/IExhibitsData';

@Injectable()
export class ExhibitsData  {
    getExhibitsData(): Observable<IExhibits []> {
        const subject = new Subject<IExhibits []>();
        setTimeout (() => {subject.next(EXHIBITSDATA); subject.complete(); }, 100);
        return subject;
    }

    getExhibitsData_(): IExhibits[] {
        return  EXHIBITSDATA;
    }

    getExhibitsSingleWork(id: number): IExhibits {
        return EXHIBITSDATA[id];
    }

    getLength(): number {
        return EXHIBITSDATA.length;
    }
}

const EXHIBITSDATA: IExhibits[] = [
    {
        postNumber: 0,
        title: 'West Vally Art Center',
        show: 'This is family',
        location: 'Surprise, AZ',
        date: '2023',
        description: 'A celebration of family love',
        pictureUrl:  '/assets/exhibits/ADB L.jpg'
    },
    {
        postNumber: 1,
        title: 'Ground Floor Artist',
        show: 'Images',
        location: 'Surprise, AZ',
        date: '2023',
        description: 'An exploration of who we are.',
        pictureUrl:  '/assets/exhibits/GFA L.jpg'
    },
    {
        postNumber: 2,
        title: 'Gilbrt Visual Art League ',
        show: 'Fine Art Show',
        location: 'Gilbert, AZ',
        date: '2023',
        description: 'The Gilbert Historical Society has a very open gallery. \
        It was a very nice place to exhibit.',
        pictureUrl:  '/assets/exhibits/GVAL L.jpg'
    },
    {
        postNumber: 3,
        title: 'Ground Floor Artist',
        show: 'Spring Fever',
        location: 'Surprise, AZ',
        date: '2023',
        description: 'Spring is that time when the imagination soars, and \
        everything seems possible.',
        pictureUrl:  '/assets/exhibits/RD L.jpg'
    },
    {
        postNumber: 4,
        title: 'SculptureTucson',
        show: 'Joy',
        location: 'Tucson, AZ',
        date: '2023',
        description: 'An exhibition of large pieces concieved to inspire, provoke, \
        and instill Joy!.',
        pictureUrl:  '/assets/exhibits/SculpTuc.jpg'
    },
    {
        postNumber: 5,
        title: 'Vista Center for the Arts',
        show: 'Art at the Vista',
        location: 'Surprise, AZ',
        date: '2022',
        description: 'The Center has a large lobby, perfect for displaying large exhibits.',
        pictureUrl:  '/assets/exhibits/IMG_0548.jpg'
    },
    {
        postNumber: 6,
        title: 'ArtIntersection',
        show: 'No Strangers Members Exhibition',
        location: 'Gilbert, AZ',
        date: '2022',
        description: 'Passion, love, and fear - our journey is to find the balance.',
        pictureUrl:  '/assets/exhibits/ArtInt.jpg'
    },
    {
        postNumber: 7,
        title: 'Herberger Theater',
        show: 'Evaporation',
        location: 'Phoenix, AZ',
        date: '2022',
        description: 'The instructions said be imaginative with “Evaporation”. \
        If evaporation is matter moving from a lower to a higher state, perhaps \
        an aspiring figure surging upward will fit in nicely.',
        pictureUrl:  '/assets/exhibits/1 Strickland Ascending_Man.jpg'
    },
    {
        postNumber: 8,
        title: '{9} The Gallery',
        show: 'Images',
        location: 'Phoenix, AZ',
        date: '2021',
        description: '[9] The Gallery uses the walls and the halls of the Lawrence Building as \
        its gallery. It is a very unique presentation space.',
        pictureUrl:  '/assets/exhibits/The Joy of Desire 3.jpg'
    },
    {
        postNumber: 9,
        title: 'Herberger Theater',
        show: 'Vacations',
        location: 'Phoenix, AZ',
        date: '2021',
        description: 'In a vacation we can set our imaginations free.  \
        This piece is a reminder of how much fun our imagination can be.',
        pictureUrl:  '/assets/exhibits/IMG_20211218_123736.jpg'
    },
    {
        postNumber: 10,
        title: 'Vista Center for the Arts',
        show: '9/11 Heros',
        location: 'Surprise, AZ',
        date: '2021',
        description: 'The Vista Center reopened on the 20th anniversary of 9/11. \
        This reflects the boldness and courage that so many demonstrated on that fateful day.',
        pictureUrl:  '/assets/exhibits/IMG_20210827_130225.jpg'
    },
    {
        postNumber: 11,
        title: 'WHAM Art Center ',
        show: 'Visions in Poetry',
        location: 'Surprise, AZ',
        date: '2021',
        description: '“Life is real! Life is earnest!” - remember Longfellow from high school? \
        This piece reflects in the spirit of the Longfellow poem, “Psalm of Life”',
        pictureUrl:  '/assets/exhibits/AscManWham1.jpg'
    },
    {
        postNumber: 12,
        title: '{9} The Gallery',
        show: 'Realism',
        location: 'Phoenix, AZ',
        date: '2021',
        description: 'This show was fun because there were so many different explorations of what is really real.',
        pictureUrl:  '/assets/exhibits/TM 2.jpg'
    },
    {
        postNumber: 13,
        title: 'The Mood Room ',
        show: 'Salon 48',
        location: 'Phoenix, AZ',
        date: '2020',
        description: 'This was an impromptu show that was organized just as the lockdown was being relaxed.',
        pictureUrl:  '/assets/exhibits/Rodeo_Dreams.jpg'
    },
    {
        postNumber: 14,
        title: 'Arizona Artist Association',
        show: 'The Heat is On',
        location: 'Phoenix, AZ',
        date: '2020',
        description: 'Because of the lockdown, this show became a virtual exhibit.',
        pictureUrl:  '/assets/exhibits/IMG_0377.JPG'
    },
    {
    postNumber: 16,
        title: 'Arizona Artists Guild',
        show: 'Eighth Annual State Exhibition',
        location: 'Surprise, AZ',
        date: '2019',
        description: 'The AAG attracts a wide variety artist. This show displayed many styles, mediums, and interest.',
        pictureUrl:  '/assets/exhibits/Strickland TenderMercies.jpg'
    },
  
  
];
