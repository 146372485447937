import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs/RX';
import { IAboutMeData } from '../data/IAboutMeData';

@Injectable()
export class AboutMeData {
    getCurrentWorkData(): Observable<IAboutMeData[]> {
        const subject = new Subject<IAboutMeData[]>();
        setTimeout (() => {subject.next(ABOUTMEDATA); subject.complete(); }, 100);
        return subject;
    }

    getCurrentWork_(): IAboutMeData[] {
        return ABOUTMEDATA;
    }

    getCurrentSingleWork(id: number): IAboutMeData {
        return ABOUTMEDATA[id];
    }

    getLength(): number {
        return ABOUTMEDATA.length;
    }
}

const ABOUTMEDATA: IAboutMeData[] = [
    {
        pictureUrl : '/assets/aboutMe/mom.JPG',
        text: 'My mother was a moderately successful armature artist. I was never that interested in here work ( I am now, and I am sorry she is not here to talk with), but I did see that art was something that could be done by people like me.  We moved around a good bit as I was growing up, so I always felt like the new kid who was trying to fit in. After a I started college, I got the idea that it would somehow be exciting to be an artist. Now I know I was more in love the idea of being an artist, rather than actually spending the lonely hours actually creating something.'
    },
    {
        pictureUrl : '/assets/aboutMe/etching.JPG',
        text: 'This is where I list all of the Art training that I received! I went to college at the University of Texas, and was even declared myself to be an art major for a couple of years. Eventually I ended up with a degree in Government. Later I attended the Corcoran School of Art in Washington, D.C.,   but only took one course.  Later I took photography and etching at the Baltimore Art Institute. This is the one etching that I did then. It does look a lot like how I felt then, believing that there would be some kind of redemption in  existential despair.'
    },
    {
        pictureUrl : '/assets/aboutMe/window.JPG',
        text: 'As I education did prepare me for an actual job, I ended up in sales. The only problem was, I was not suited to sales, and I did like the work. But I stayed with it for twenty years, just because I didn’t know what else to do. And what about doing art? I tried from time to time, but it seems that beyond creating something just for the activity, I really did not have anything to say. This photograph is of my living room at night, and of the shadows of the street lights on the wall. I used to sit there with a drink, fascinated by the images, I think hoping to something that I could name. This picture and the etching above is all that remains from all of those years.'
    },
    
    {
        pictureUrl : '/assets/aboutMe/vickie.JPG',
        text: 'After many years, I decided to try painting again. I had been thinking about ideas for a while, and decided to see where I was at after so much. This time, I found I was doing this for only myself, I wasn’t concerned about what it might look good or fashionable. It was a very liberating idea. Some of the paints and brushes had been my mothers, who had died 30 years earlier.'
    },
    {
        pictureUrl : '/assets/aboutMe/window.JPG',
        text: 'In trying to workout images for a painting, I stated making clay figures. I liked the way the figures worked together in space, and decided that they ideas would work best as life size forms. I started working with sheet brass because it was a way to create a human form that I could do. '
    },
    {
        pictureUrl : '/assets/aboutMe/SAM_2135_.JPG',
        text: 'I found that enjoyed the manual process of creating soft human forms from industrial materials. The surface shows all of the assembled joints and pieces, but from a distance the individual pieces melt into a whole.'
    },
    {
        pictureUrl : '/assets/aboutMe/SAM_0290_.JPG',
        text: 'I found that enjoyed the manual process of creating soft human forms from industrial materials. The surface shows all of the assembled joints and pieces, but from a distance the individual pieces melt into a whole.'
    },
    
];