import { Component, OnInit, Injectable, ElementRef, Output } from "@angular/core";
declare var $: any;

@Component({
   // moduleId: module.id.toString(),
    selector: "modalWindow",
    templateUrl: "modalWindow.component.html"
})

export class ModalWindowComponent {
    private bodyText: string;
    private bodyImage: string = "../../app/_content/SAM_67.JPG";
    
 /*   constructor(private el: ElementRef) {
        this.element = $(el.nativeElement);
    } */

    ngOnInit(): void {
        this.bodyText = "This text can be updated in modal 1";
        this.closeModal();
        
    /*    let modal = this;
        this.element.on('click', function (e: any) {
            var target = $(e.target);
            if (!target.closest('.modal-body').length) {
                modal.closeModal();
            }
        }); */
    }

    openModal(id: string): void {
        $("modalWindow").show();
        $("modalWindow").addClass("modal-open");
    }

    closeModal(): void {
        $("modalWindow").removeClass("modal-open");
        $("modalWindow").hide();
    }
}