import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule} from '@angular/router';
import { FlexLayoutModule} from '@angular/flex-layout';
import { FormsModule} from '@angular/forms';
import { MainAppComponent } from './mainApp.component';
import { appRoutes} from './routes';
import { NavBarComponent} from './navigation/navbar.component';
import { HomePageComponent } from './pages/home/HomePage.component';
import { CurrentWorkComponent } from './pages/currentWork/currentWork.component';
import { ContactMeComponent } from './pages/contactPage/contactMe.component';
import { ExhibitsComponent } from './pages/exhibits/exhibition.component';
import { WorkOneComponent } from './pages/currentWork/works/workOne.component';
import { AboutMeComponent } from './pages/About/aboutMe.component';
import { ModalWindowComponent } from './common/modalWindow.component';
import { CarouselComponent } from './common/carousel.component';
import { JQ_TOKEN } from './services/jQuery.service';
import { MessageService } from './services/message.service';
import { CurrentWorkData } from './services/currentWorkData.service';
import { AboutMeData } from './services/aboutMeData.service';
import { AboutMePopoverData } from './services/aboutMePopoverData.service';
import { ExhibitsData } from './services/exhibitsData.service';
import { PopoverModule } from 'ng2-popover';
import { PopoverContent } from './common/popoverContent.component';
import { ThumbnailData } from './services/thumbnailData.service';
import { Test } from './pages/testFoundation/ftest';
import { BackgroundImage } from './common/backgroundImage.component';
import { FunPlacesComponent} from './pages/funThings/funPlaces.component';
import { ResourcesComponent} from './pages/funThings/resources.component';
import { OddsEndComponent} from './pages/funThings/oddsEnd.component';
import { IronManComponent} from './pages/funThings/ironMan.component';
import { FunComponent } from './pages/fun/fun.component';
const jQuery = window['$'];

@NgModule({
  declarations: [
    NavBarComponent,
    HomePageComponent,
    MainAppComponent,
    CurrentWorkComponent,
    ContactMeComponent,
    ExhibitsComponent,
    WorkOneComponent,
    AboutMeComponent,
    ModalWindowComponent,
    CarouselComponent,
    PopoverContent,
    FunPlacesComponent,
    ResourcesComponent,
    OddsEndComponent,
    IronManComponent,
    FunComponent,
    Test
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    RouterModule.forRoot(appRoutes),
    FormsModule,
    PopoverModule
  ],
  providers: [
    CurrentWorkData,
    ExhibitsData,
    ThumbnailData,
    AboutMePopoverData,
    AboutMeData,
    MessageService,
    { provide: JQ_TOKEN, useValue: jQuery },
//    SetBackground,
    BackgroundImage
  ],
  bootstrap: [MainAppComponent]
})
export class AppModule { }
