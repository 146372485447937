import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { MessageService } from './services/message.service';
import { BackgroundImage } from './common/backgroundImage.component';
//import { JQ_TOKEN } from './services/jQuery.service';

@Component({
    selector: 'app-main',
    template: `
    <div class="grid-container full bodyclass"  style="max-width: 1200px; " >
    <div id="background"  >
        <div class="">
            <app-nav-bar ></app-nav-bar>
        </div>
    <div *ngIf="message" class="alert alert-success">{{message.text}}</div>
    <router-outlet></router-outlet>
    </div>
    </div>
    `
})
export class MainAppComponent implements OnInit {
   // base = 'assets/background2.jpg';
   // home = 'assets/background2.jpg';
    image: string;

    subscription: Subscription;

    constructor( private backgroundImage: BackgroundImage, private messageService: MessageService) {
        this.subscription = this.messageService.getMessage().subscribe(message => {
            this.image = message.text;
            if (message.text === 'home') {
                backgroundImage.setHomeImage();
            } else if (message.text === 'base') {
                backgroundImage.setBaseImage();
            } else {
                backgroundImage.setImage(message.text );
            }
        });
    }

    ngOnInit() {
      // this.$('#background').css('background-image', 'url(' + this.backgound + ')');
    }
}


